@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  background-color: #08070c;
}

.overflow-hidden {
  overflow: hidden;
}


body{
  background-color: #08070C;
  
}
.bg-black-variation{
  background-color: #08070C;
}

.rubik-wet-paint-regular {
  font-family: "Rubik Wet Paint", system-ui;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.2rem;
}

.ultra-regular {
  font-family: "Ultra", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
}



.network-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: white;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 5px;
}

/* Add this CSS to your styles */
.fixed {
  position: fixed;
}



.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.z-50 {
  z-index: 50;
}

.glass-effect{
 /* From https://css.glass */
background: rgba(255, 255, 255, 0.19);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.6px);
-webkit-backdrop-filter: blur(6.6px);
border: 1px solid rgba(255, 255, 255, 0.3);
}